import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Tilt from "react-parallax-tilt";
import { Link } from 'react-router-dom';

import { default as product_1, default as product_2, default as product_3 } from "./img/product_bg_1.png";
import styles from "./ProductSlider1.module.scss";

function ProductSlider1() {
  const mainProductList = [
    {
      avatar: product_1,
      name: "The Modern MasterClass Art with Class",
      description: "Inspired by this vision of the future, our team of architects, 3D designers, marketers, and entrepreneurs created a powerful framework where every person, business",
      pricecard: [
        {
          priceeth: "2.00", price: "$6,134.34", currency: "ETH", sale: true, sale_end_time: '15, 12, 2022'
        }
      ]
    },
    {
      avatar: product_2,
      name: "The Modern MasterClass Art with Class",
      description: "Inspired by this vision of the future, our team of architects, 3D designers, marketers, and entrepreneurs created a powerful framework where every person, business",
      pricecard: [
        {
          priceeth: "2.00", price: "$6,134.34", currency: "ETH", sale: true, sale_end_time: '15, 12, 2022'
        }
      ]
    },
    {
      avatar: product_3,
      name: "The Modern MasterClass Art with Class",
      description: "Inspired by this vision of the future, our team of architects, 3D designers, marketers, and entrepreneurs created a powerful framework where every person, business",
      pricecard: [
        {
          priceeth: "2.00", price: "$6,134.34", currency: "ETH", sale: true, sale_end_time: '15, 12, 2022'
        }
      ]
    },
    {
      avatar: product_3,
      name: "The Modern MasterClass Art with Class",
      description: "Inspired by this vision of the future, our team of architects, 3D designers, marketers, and entrepreneurs created a powerful framework where every person, business",
      pricecard: [
        {
          priceeth: "2.00", price: "$6,134.34", currency: "ETH", sale: true, sale_end_time: '15, 12, 2022'
        }
      ]
    },
  ];

  return (
    <Splide hasTrack={false} aria-label="..." className="products-splide px-0"
      aria-label=""
      // onArrowsMounted={(splide, prev, next) => { console.log(prev, next) }}
      options={{
        rewind: true,
        type: "loop",
        slideIndex: 2,
        isNavigation: true,
        pagination: false,
        paginationDirection: "ltr",
        arrows: true,
        autoHeight: true,
        gap: "0rem",
        padding: '0rem',
        perMove: 1,
        perPage: 1,
        autoplay: false,
        pauseOnHover: false,
        resetProgress: false,
      }}>
      <div className="custom-wrapper slider-one-wrapper px-0">
        <SplideTrack>
          {mainProductList.map((item, index) => {
            return (
              <SplideSlide key={index.toString()}>
                <div className={styles.product}>
                  <div className="row align-items-start">
                    <div className="col-md-12">
                      <Tilt
                        className="h-100"
                        perspective={800}
                        tiltMaxAngleY="5"
                        tiltMaxAngleX="8"
                        scale={1.0}
                        tiltReverse={true}
                        trackOnWindow={false}
                        transitionSpeed={900}
                        reset={true}
                      >
                      </Tilt>
                      <div className={styles.product__body}>
                        <img
                          src={item.avatar}
                          className={"rounded mx-auto " + styles.product__avatar}
                          alt="..."
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <AnimationOnScroll
                        animateIn="animate__bounce"
                        animationOut="animate__fadeOut"
                        // animateInDuration={800}
                        delay={120}
                        initiallyVisible={true}
                        animateOnce={true}
                      >
                        <h1 className={styles.product__heading}>{item.name}</h1>
                      </AnimationOnScroll>
                      <AnimationOnScroll
                        animateIn="animate__fadeIn"
                        animationOut="animate__fadeOut"
                        initiallyVisible={true}
                        animateOnce={true}
                      >
                        <p className={"mb-5 " + styles.product__description}>{item.description}</p>
                        <div className="row">
                          <div className="col-xl-19 col-lg-20 col-md-21 col-sm-24 col-xs-24">
                            <PriceCard data={item.pricecard} styleName="mb-4" />
                            <Link to="/marketplace" className="btn btn-main w-100">
                              Go To The Marketplace
                            </Link>
                          </div>
                        </div>
                      </AnimationOnScroll>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </SplideTrack>
        <div className="row align-items-start">
          <div className="col-md-12"> </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-xl-19 col-lg-20 col-md-21 col-sm-24 col-xs-24">
                <div className={"splide__arrows " + styles.splide__arrows}>
                  <button className={"splide__arrow splide__arrow--prev " + styles.splide__arrow}>
                    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.7656 5.25L0 5.25L0 8.75L18.7656 8.75V14L25 7L18.7656 0V5.25Z" fill="white" />
                    </svg>
                  </button>
                  <button className={"splide__arrow splide__arrow--next " + styles.splide__arrow}>
                    <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.7656 5.25L0 5.25L0 8.75L18.7656 8.75V14L25 7L18.7656 0V5.25Z" fill="white" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Splide>
  );
}

export default ProductSlider1;

function PriceCard(props) {
  return <>
    {props.data.map((item, index) => {
      return (<div key={index.toString()} className={`text-center ${styles.sliderPrice} ${props.styleName}`}>
        <h3 className={styles.sliderPrice__eth}> {item.priceeth} {item.currency}</h3>
        <p className={styles.sliderPrice__price}> {item.price} </p>
        <div className={styles.sliderPrice__wrapper}>
          <h3 className={styles.sliderPrice__heading}> Sale ending in </h3>
          <p className={styles.sliderPrice__time}> {item.sale_end_time} </p>
        </div>
      </div>
      );
    })}
  </>;
} 