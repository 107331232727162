import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "./TextBlock.scss";
import boxes from "../../Assets/img/boxes.png";

function TextBlock(props) {
  return (
    <section className="pt-lg pb-lg">
      <div className="container-fluid section-x-margins" id="">
        <Row className="align-items-center">
          <Col md={12}>
            <div className="mb-4 mb-md-0">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                animationOut="animate__fadeOut"
                animateOnce={true}
              >
                <h3 className="text-image__sub-heading">
                  {props.small_text}
                </h3>
                <h1 className="text-image__heading">{props.title}</h1>
                <p className="text-image__description mb-5">{props.description}</p>
                <Button className="btn btn-main" href={props.btnLink} target="_blank">
                  {props.btnText}
                  {/* {props.isBlog ? "Blog" : "GitHub"} */}
                  {/* {!props.isBlog && props.btnLink && ()} */}
                </Button>
              </AnimationOnScroll>
            </div>
          </Col>

          <Col md={12} className="project-card">
            <div className="project-card-view">
              <img className="img-fluid" src={props.imgPath} alt="card-img" />
            </div>
          </Col>
        </Row >
      </div>
    </section>
  );
}

TextBlock.defaultProps = {
  imgPath: boxes,
  // isBlog={false}
  small_text: "WALLET STATUS",
  title: "Real Time Wallet Access",
  description: "With a shared passion for luxury, technological innovation, and gamification, we created a friendly, interactive, and multi-functioning platform for customers and vendors to interact in a holistic metaverse experience.",
  btnText: "LAUNCH WALLET NOW",
  btnLink: "#"
};
export default TextBlock;
