import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../Components/Shared/NavBar/NavBar";
import { useAuthentication } from "../Hooks/useAuthentication";

const DashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const [user, isAuthenticated, authLogin, authLogout] = useAuthentication();
  // const [user, setUser] = useState(localStorage.getItem("user"));

  useEffect(() => {
    console.log("authLogin..." + authLogin);
    if (isAuthenticated) {
      console.log("Logged in User...");
    } else {
      console.log("NOT Logged in User...");
      navigate(`/404`);
    }
  }, []);

  return (
    <>
      <header>
        <NavBar />
      </header>
      <main>
        {/* <br />
        <br />
        <br />
        <br /> */}
        {/* <h1>user : {JSON.stringify(user)} </h1> */}

        {children}
      </main>
    </>
  );
};

export default DashboardLayout;
