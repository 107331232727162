import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import React3dEarth from "react-3d-earth";
// import { useDarkMode } from "../../../../Hooks/useDarkMode";

import earthImg from "./hologram-map.svg";
import "./Globe.scss";

class Globe extends React.Component {
  handleClick = () => {
    // force a re-render
    this.forceUpdate();
  };

  render() {
    console.log("App component: render()");
    return (
      <>
        {/* <button onClick={this.handleClick}>Say something</button> */}
        <div
          className="globe"
          id="Globe"
          style={{
            position: "relative",
          }}
        >
          {/* <h2>passed {this.props.isDarkMode.toString()}</h2> */}
          {(() => {
            if (this.props.isDarkMode) {
              return <GlobeTwo isDarkMode="#281637" />;
            } else {
              return <GlobeOne isDarkMode="#C301AF" />;
            }
          })()}
        </div>
      </>
    );
  }
}

export default Globe;

function GlobeOne({ isDarkMode }) {
  useEffect(() => {
    // alert(isDarkMode);
  }, [isDarkMode]);

  return (
    <React3dEarth
      style={{
        width: "100%",
        minHeight: "60vh",
        height: "100vh",
        position: "absolute",
        zIndex: "0",
        // background: "green",
      }}
      config={{
        radius: 23,
        mobileRadius: 10,
        // backgroundColor: this.props.isDarkMode ? "red" : "blue",
        // backgroundColor: isDarkMode,
        backgroundColor: "#F2F2F2",
        // backgroundOpacity: '0.5',
        // flagScale:1,
        // flagLat:39.56,
        // flagLon: 116.20,
        // flagColor: 'green',
        dotColor: "#FE01E4",
        // autoRotationSpeed: 3,
        // draggingRotationSpeed:5,
        // textureSrc: earthImg,
      }}
    />
  );
}

function GlobeTwo({ isDarkMode }) {
  useEffect(() => {
    // alert(isDarkMode);
  }, [isDarkMode]);

  return (
    <React3dEarth
      style={{
        width: "100%",
        minHeight: "60vh",
        height: "100vh",
        position: "absolute",
        zIndex: "0",
      }}
      config={{
        radius: 23,
        mobileRadius: 10,
        // backgroundColor: this.props.isDarkMode ? "red" : "blue",
        backgroundColor: "#281637",
        // backgroundOpacity: '0.5',
        // backgroundColor: "#00000000",
        // flagScale:1,
        // flagLat:39.56,
        // flagLon: 116.20,
        // flagColor: 'green',
        dotColor: "#FE01E4",
        // autoRotationSpeed: 3,
        // draggingRotationSpeed:5,
        // textureSrc: earthImg,
      }}
    />
  );
}
