import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import * as yup from "yup";

import AuthLayout from "../../Layouts/AuthLayout";
import css from "./Auth.module.scss";
import Avatars from "./img/Avatars.png";

const SignupSchema = yup.object().shape({
  r_email: yup.string()
    .required("Email is required.")
    // .email("Valid email is required.")
    .min(3, 'Name is too short - should be 8 chars minimum.')
    .max(50, "Name should be miximum 50 chars long."),
});

function ForgetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(SignupSchema)
  });
  const onSubmit = (data) => {
    alert(JSON.stringify(data));
  };

  return (
    <>
      <AuthLayout>
        <div className={"animate__animated animate__fadeIn " + css.section}>
          <div className="container-fluid px-0">

            <div className="row g-0 h-md-100vh align-items-center">
              <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-24 col-24 " + css.bg}>
                <div className="h-100vh d-flex align-items-center justify-content-center">
                  <div className={"w-100 " + css.card}>
                    <div>
                      <br /><br /><br />
                      <h1 className="heading text-light mb-2">Forget Password ?</h1>
                      <p className="text-light">Please enter your Email below to get the password reset link</p>
                      <br />
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={css.control}>
                          <label className={css.controlLabel} htmlFor="r_email">Email*</label>
                          <input className={css.controlInput} {...register("email")} type="email" placeholder="Enter your email" id="r_email" />
                          {errors.email && <p className={css.controlMsg}>{errors.email.message}</p>}
                        </div>
                        <button type="submit" className="btn btn-gradient w-100 my-4">Sent</button>

                        <div className="text-center text-white">
                          <p className="mb-0 d-inline">Take me to: </p>
                          <Link to="/login" className="ms-2 mb-0 d-inline text-decoration-none text-white">
                            <b> Sign in </b>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-24 col-24 " + css.bg2}>
                <div className="h-100vh d-flex align-items-center justify-content-center">
                  <div>
                    <div className={" " + css.card}>

                      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C25.4247 40 40 25.4247 40 0C40 25.4247 54.5753 40 80 40C54.5753 40 40 54.5753 40 80C40 54.5753 25.4247 40 0 40Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C7.62742 12 12 7.62742 12 0C12 7.62742 16.3726 12 24 12C16.3726 12 12 16.3726 12 24C12 16.3726 7.62742 12 0 12Z" fill="#FEC84B" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M64 24C69.0849 24 72 21.0849 72 16C72 21.0849 74.9151 24 80 24C74.9151 24 72 26.9151 72 32C72 26.9151 69.0849 24 64 24Z" fill="#FEC84B" />
                      </svg>

                      <h3 className={css.card_heading}>To deliver the most impactful and human-focused e-commerce experience</h3>
                      <p className={css.card_text}>
                        Create a free account and get full access to all features of X-nation cummunity.
                      </p>
                      <div className={css.img}>
                        <img className={css.img_avatars} src={Avatars} alt="" />
                        <span className={css.img_text}>Join 40,000+ users</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default ForgetPassword;
