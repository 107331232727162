
import About from "./../../Components/About/About";
import Products from "./../../Components/Products/Products";
import Subscribe from "./../../Components/Subscribe/Subscribe";
import TextBlock from "./../../Components/TextImageBlock/TextBlock";
import TextImageBlock from "./../../Components/TextImageBlock/TextImageBlock";
import DefaultLayout from "./../../Layouts/DefaultLayout";
import HeroHome from "./Components/Hero/HeroHome";
import RoadmapBlock from "./Components/RoadmapBlock/RoadmapBlock";
import SocialContact from "./Components/SocialContact/SocialContact";
import TeamsBlock from "./Components/TeamsBlock/TeamsBlock";

function HomePage() {
  return (
    <>
      <DefaultLayout className="">
        <div className="animate__animated animate__fadeIn">
          <HeroHome />
          <About />
          <TextBlock />
          <TextImageBlock />
          <RoadmapBlock />
          <Products />
          <SocialContact />
          <TeamsBlock />
          <Subscribe />
        </div>
      </DefaultLayout>
    </>
  );
}

export default HomePage;
