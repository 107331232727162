export default [
  {
    "featureType": "all",
    "elementType": "all",
    "stylers": [
      {
        "invert_lightness": true
      },
      {
        "saturation": 10
      },
      {
        "lightness": 30
      },
      {
        "gamma": 0.5
      },
      {
        "hue": "#8500ff"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "hue": "#ff00e5"
      }
    ]
  }
];
