import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

import DefaultLayout from "../../Layouts/DefaultLayout";
import MapsSearch from "./Components/MapFilter/MapsSearch";

function MapPage() {

  let { id } = useParams();

  useEffect(() => {
    console.log(`/something/${id}`);
  }, []);

  return (
    <>
      <Helmet>
        <title> Xnation - Map </title>
        <meta name="description" content="Xnation Map" />
      </Helmet>

      <DefaultLayout>
        <section className="animate__animated animate__fadeIn marketplace-section" id="marketplace_block">
          <div className="container-fluid px-0">
            <MapsSearch />
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default MapPage;
