import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "../../Layouts/DashboardLayout";
import css from "./Auth.module.scss";

function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   upadateLoad(false);
    // }, 1200);

    // return () => clearTimeout(timer); 
    // navigate(`/dashboard`);
    setUser(JSON.parse(window.localStorage.getItem("user")));
    console.log(user);
  }, []);

  return (
    <>
      <DashboardLayout>
        <div className={"animate__animated animate__fadeIn " + css.section}>
          <div className="container-fluid px-0">

            <div className="row g-0 h-md-100vh align-items-center">
              <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-24 col-24 " + css.bg}>
                <div className="h-100vh d-flex align-items-center justify-content-center">
                  <div className={"w-100 " + css.card}>
                    <div>
                      <br /><br /><br />
                      <h1 className="heading text-light mb-2">Dashboard</h1>
                      <p className="text-light">
                        {JSON.stringify(user)}
                        {/* {user.l_name} */}
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-24 col-24 " + css.bg2}>
                <div className="h-100vh d-flex align-items-center justify-content-center">
                  <div>
                    <div className={" " + css.card}>

                      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 40C25.4247 40 40 25.4247 40 0C40 25.4247 54.5753 40 80 40C54.5753 40 40 54.5753 40 80C40 54.5753 25.4247 40 0 40Z" fill="white" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12C7.62742 12 12 7.62742 12 0C12 7.62742 16.3726 12 24 12C16.3726 12 12 16.3726 12 24C12 16.3726 7.62742 12 0 12Z" fill="#FEC84B" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M64 24C69.0849 24 72 21.0849 72 16C72 21.0849 74.9151 24 80 24C74.9151 24 72 26.9151 72 32C72 26.9151 69.0849 24 64 24Z" fill="#FEC84B" />
                      </svg>

                      <h3 className={css.card_heading}>
                        Lorem, ipsum dolor.
                      </h3>
                      <p className={css.card_text}>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore nisi beatae esse accusantium rem fuga dolore repellendus obcaecati. Culpa enim sit facere vel?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </DashboardLayout>
    </>
  );
}

export default Dashboard;
