import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";
import * as yup from "yup";
import { AnimationOnScroll } from "react-animation-on-scroll";

import AuthLayout from "../../Layouts/AuthLayout";
import css from "./Auth.module.scss";
import { ReactComponent as MetaMaskLogo } from "./img/metamask.svg";
// import Avatars from "./img/Avatars.png";

function ConnectMetaMask() {

  return (
    <>
      <AuthLayout>
        <div className={"animate__animated animate__fadeIn " + css.section}>
          <div className="container-fluid px-0">

            <section className="pt-lg pb-lg">
              <div className="container-fluid section-x-margins" id="">
                <div className="row align-items-center">
                  <div className="col-md-24">
                    <div className="text-center mb-4 mb-md-0">
                      <br /><br /><br />
                      {/* <AnimationOnScroll
                        animateIn="animate__fadeIn"
                        animationOut="animate__fadeOut"
                        animateOnce={true}
                      >
                      </AnimationOnScroll> */}
                      <h3 className="text-image__sub-heading">
                        LETS START BY METAMASK
                      </h3>
                      <h1 className="text-image__heading"> Connect to MetaMask</h1>
                      <div>
                        <p className="text-image__description w-auto d-inline-block mb-5">
                          First thing first, Connect to metamask and create your profile after.
                        </p>
                      </div>
                      <div>
                        <br /><br />
                        <MetaMaskLogo />
                        <br /><br />
                      </div>
                      <button className="btn btn-gradient">
                        Connect Wallet
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default ConnectMetaMask;
