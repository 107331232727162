import { AnimationOnScroll } from "react-animation-on-scroll";
import { Col, Row } from "react-bootstrap";
// Default theme
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";

import "./RoadmapBlock.scss";

function RoadmapBlock() {
  return (
    <section className="hero-section pt-normal pb-normal" id="roadmap_block">
      <div className="container-fluid section-x-margins">
        <Row className="justify-content-center">
          <Col xl={20} lg={20} md={22} sm={24} xs={24} className="">
            <div className="roadmap text-center">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                animationOut="animate__fadeOut"
                animateOnce={true}
              >
                <h3 className="roadmap__sub-heading">HOW WE PROCCESS</h3>
                <h2 className="roadmap__heading">Road Map</h2>
              </AnimationOnScroll>
              <p className="roadmap__description mb-5">
                Inspired by this vision of the future, our team of architects,
                3D designers, marketers, and entrepreneurs created a powerful
                framework where every person, business, or idea would have
                seamless entry into the metaverse and easy access to the
                purchasing power of Cryptocurrency.
              </p>

              <section className="timeline-wrapper">
                <ol>
                  <li>
                    <div className="outer-wrapper">
                      <div className="timeline timeline--bottom">
                        <div className="timeline__body">
                          <p className="timeline__text">
                            Launch Date <br />
                            <b className="meta-date">May, 01</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="outer-wrapper">
                      <div className="timeline timeline--top">
                        <div className="timeline__body">
                          <p className="timeline__text">
                            Tech Audit
                            {/* Completed on */}
                            <br />
                            <b className="meta-date">May, 02</b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="outer-wrapper">
                      <div className="timeline timeline--bottom">
                        <div className="timeline__body">
                          <p className="timeline__text">
                            Open Telegram <br /> Community
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="outer-wrapper">
                      <div className="timeline timeline--top">
                        <div className="timeline__body">
                          <p className="timeline__text">
                            New Character <br /> Release
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="outer-wrapper">
                      <div className="timeline timeline--bottom">
                        <div className="timeline__body">
                          <p className="timeline__text">
                            New Cities <br /> Release
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li></li>
                </ol>
              </section>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default RoadmapBlock;
