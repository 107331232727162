import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';

import DefaultLayout from "../../Layouts/DefaultLayout";
import NftDetail from "./Components/NftDetail/NftDetail";
import { default as product_1, default as product_2, default as product_3 } from "./img/product_bg_1.png";

function NftDetailPage() {
  const [productID, setproductID] = useState();
  const productList = [
    {
      avatar: product_1,
      name: "the desperate one",
      price: "1.23",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_1,
      name: "the Modern Mona",
      price: "1.23",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_2,
      name: "Fluffy One",
      price: "2.22",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_3,
      name: "Stylo Negro",
      price: "1.00",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_3,
      name: "Stylo Negro",
      price: "1.00",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_3,
      name: "the desperate one",
      price: "1.00",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_3,
      name: "Stylo Negro",
      price: "1.00",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_3,
      name: "the desperate one",
      price: "1.00",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_3,
      name: "Stylo Negro",
      price: "1.00",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
    {
      avatar: product_3,
      name: "Wrath of titans",
      price: "1.43",
      currency: "ETH",
      in_stock: 3,
      product_no: "#5245622"
    },
  ];

  let { id } = useParams();

  useEffect(() => {
    console.log(`/NFT/${id}`);
    setproductID(id);
  }, []);

  return (
    <>
      <Helmet>
        <title> Xnation - NFT {id} </title>
        <meta name="description" content="MarketPlace" />
      </Helmet>

      <DefaultLayout>
        <section className="animate__animated animate__zoomIn marketplace-section pt-lg pb-lg" id="marketplace_block">
          <div className="container-fluid section-x-margins">
            <h2 className="text-white mt-5 mt-md-4 mt-lg-4">
              NFT   
              #{productID}
            </h2>
            <NftDetail />
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default NftDetailPage;
