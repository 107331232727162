import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Tilt from "react-parallax-tilt";
import { Link } from 'react-router-dom';

import { default as product_1, default as product_2, default as product_3 } from "./../../img/product_detail_1.png";
import styles from "./ProductDetail.module.scss";

function ProductDetail() {
  const productThumbnails = [product_1, product_2, product_3, product_3];

  return (
    <>
      <div className={styles.product}>
        <div className="row align-items-center">
          <div className="col-md-13">
            <div className={styles.product__body}>
              <Splide hasTrack={false} aria-label="..." className="products-splide"
                aria-label=""
                // onArrowsMounted={(splide, prev, next) => { console.log(prev, next) }}
                options={{
                  rewind: true,
                  type: "loop",
                  slideIndex: 2,
                  isNavigation: true,
                  pagination: false,
                  paginationDirection: "ltr",
                  arrows: true,
                  autoHeight: true,
                  gap: "1.75rem",
                  padding: { left: "0rem", right: "0rem" },
                  perMove: 1,
                  perPage: 1,
                  autoplay: false,
                  pauseOnHover: false,
                  resetProgress: false,
                }}>
                <div className="custom-wrapper">
                  <SplideTrack>
                    {productThumbnails.map((item, index) => {
                      return (
                        <SplideSlide key={index.toString()}>
                          <Tilt
                            className="h-100"
                            perspective={800}
                            tiltMaxAngleY="5"
                            tiltMaxAngleX="8"
                            scale={1.0}
                            tiltReverse={true}
                            trackOnWindow={false}
                            transitionSpeed={900}
                            reset={true}
                          >
                            <div className="p-4">
                              <img
                                src={item}
                                className={"rounded mx-auto " + styles.product__avatar}
                                alt="..."
                              />
                            </div>
                          </Tilt>
                        </SplideSlide>
                      );
                    })}
                  </SplideTrack>

                  <div className={`splide__arrows mt-5 ` + styles.arrows}>
                    <button className={`splide__arrow splide__arrow--prev ` + styles.arrow}>
                      <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.7656 5.25L0 5.25L0 8.75L18.7656 8.75V14L25 7L18.7656 0V5.25Z" fill="white" />
                      </svg>
                    </button>
                    <button className={`splide__arrow splide__arrow--next ` + styles.arrow}>
                      <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.7656 5.25L0 5.25L0 8.75L18.7656 8.75V14L25 7L18.7656 0V5.25Z" fill="white" />
                      </svg>
                    </button>
                  </div>

                </div>
              </Splide>
            </div>
          </div>
          <div className="col-md-11">
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animationOut="animate__fadeOut"
              initiallyVisible={true}
              animateOnce={true}
            >
              <h1 className={styles.product__heading}>The Modern MasterClass</h1>
              <p className={"mb-5 " + styles.product__description}>
                Inspired by this vision of the future, our team of architects, 3D designers, marketers, and entrepreneurs created a powerful framework where every person, business
              </p>
              <div className={"mb-5 " + styles.product__priceWrapper}>
                <div className={styles.product__priceEth}>1.23 ETH</div>
                <div className={styles.product__priceDollar}>$4,213.88</div>
                <div className={styles.product__stock}>10 in Stock</div>
              </div>
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <Link to="/marketplace" className="btn btn-gradient w-100 mb-3">
                    <span>Buy Now</span>
                  </Link>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <Link to="/marketplace" className="btn btn-main w-100 mb-3">
                    Add to favouraites
                  </Link>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetail;

function PriceCard(props) {
  return <>
    {props.data.map((item, index) => {
      return (<div key={index.toString()} className={`text-center ${styles.sliderPrice} ${props.styleName}`}>
        <h3 className={styles.sliderPrice__eth}> {item.priceeth} {item.currency}</h3>
        <p className={styles.sliderPrice__price}> {item.price} </p>
        <div className={styles.sliderPrice__wrapper}>
          <h3 className={styles.sliderPrice__heading}> Sale ending in </h3>
          <p className={styles.sliderPrice__time}> {item.sale_end_time} </p>
        </div>
      </div>
      );
    })}
  </>;
} 