import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import DefaultLayout from "../../Layouts/DefaultLayout";
import Product from "./Components/Product/Product";
import { default as product_1, default as product_2, default as product_3 } from "./img/product_2.png";

import style from "./Product.module.scss";
import MyAlert from "./../../Components/UI/MyAlert/MyAlert";

function Marketplace() {
  const product_list = [{
    avatar: product_1,
    name: "the desperate one",
    price: "1.23",
    currency: "ETH",
    in_stock: 23,
    product_no: "5245622"
  },
  {
    avatar: product_1,
    name: "the Modern Mona",
    price: "1.23",
    currency: "ETH",
    in_stock: 2,
    product_no: "5245622"
  },
  {
    avatar: product_2,
    name: "Fluffy One",
    price: "2.22",
    currency: "ETH",
    in_stock: 1,
    product_no: "5245622"
  },
  {
    avatar: product_3,
    name: "Stylo Negro",
    price: "1.00",
    currency: "ETH",
    in_stock: 4,
    product_no: "5245622"
  },
  {
    avatar: product_3,
    name: "Stylo Negro",
    price: "1.00",
    currency: "ETH",
    in_stock: 1,
    product_no: "5245622"
  },
  {
    avatar: product_3,
    name: "the desperate one",
    price: "1.00",
    currency: "ETH",
    in_stock: 2,
    product_no: "5245622"
  },
  {
    avatar: product_3,
    name: "Stylo Negro",
    price: "1.00",
    currency: "ETH",
    in_stock: 4,
    product_no: "5245622"
  },
  {
    avatar: product_3,
    name: "the desperate one",
    price: "1.00",
    currency: "ETH",
    in_stock: 33,
    product_no: "5245622"
  },
  {
    avatar: product_3,
    name: "Stylo Negro",
    price: "1.00",
    currency: "ETH",
    in_stock: 6,
    product_no: "5245622"
  },
  {
    avatar: product_3,
    name: "Wrath of titans",
    price: "1.43",
    currency: "ETH",
    in_stock: 8,
    product_no: "5245622"
  },];
  const [searchText, setSearchText] = useState("");
  const [productList, setProductList] = useState(product_list);

  // exclude column list from filter
  const excludeColumns = ["in_stock", "avatar", "currency"];

  // handle change event of search input
  const handleChange = value => {
    setSearchText(value);
    filterData(value);
  };

  // filter records by search text
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setProductList(product_list);
    else {
      const filteredData = product_list.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setProductList(filteredData);
    }
  }

  useEffect(() => { }, [productList]);

  const productListRender = () => {
    if (productList.length === 0 && searchText !== "") {
      return <MyAlert type="danger" text={`No records found for <b>${searchText}</b>!`} />;
    }
    else if (productList.length === 0 && searchText === "") {
      return <MyAlert type="info" text="No records found to display!" />;
    }
    else if (searchText !== "" && productList.length === 0) {
      return <MyAlert type="danger" text={`No records found for <b>${searchText}</b>!`} />;
    } else {
      return <>
        {productList.map((item, index) => {
          return (
            <div className="col">
              {/* <AnimationOnScroll
                  animateIn="animate__bounceIn"
                  animationOut="animate__fadeOut"
                  delay={20}
                  animateOnce={true}
                >
                </AnimationOnScroll> */}
              <div className="animate__animated animate__bounceIn">
                <Product item={item} />
              </div>
            </div>
          );
        })}
      </>;
    }
  }

  return (
    <>
      <Helmet>
        <title> Xnation - MarketPlace </title>
        <meta name="description" content="Xnation - MarketPlace" />
      </Helmet>

      <DefaultLayout>
        <section className="animate__animated animate__fadeIn marketplace-section pt-lg pb-lg" id="marketplace_block">
          <div className="container-fluid section-x-margins">
            <div className="row">
              <div className="col-md-16">
                <h1 className={style.pageTitle}>Market Place</h1>
              </div>
              <div className="col-md-8">
                {/* <div className=" mb-3" > */}
                <div className={"ms-auto w-auto " + style.input}>
                  <span className={style.inputText} id="basic-addon1">
                    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="28" height="27" rx="13.5" fill="url(#paint0_linear_122_1240)" />
                      <g clip-path="url(#clip0_122_1240)">
                        <path d="M17.9167 16.25H17.2583L17.025 16.0138C17.8417 15.0162 18.3333 13.7213 18.3333 12.3125C18.3333 9.17125 15.9083 6.625 12.9167 6.625C9.925 6.625 7.5 9.17125 7.5 12.3125C7.5 15.4537 9.925 18 12.9167 18C14.2583 18 15.4917 17.4838 16.4417 16.6263L16.6667 16.8713V17.5625L20.8333 21.9288L22.075 20.625L17.9167 16.25ZM12.9167 16.25C10.8417 16.25 9.16667 14.4913 9.16667 12.3125C9.16667 10.1337 10.8417 8.375 12.9167 8.375C14.9917 8.375 16.6667 10.1337 16.6667 12.3125C16.6667 14.4913 14.9917 16.25 12.9167 16.25Z" fill="white" />
                      </g>
                      <defs>
                        <linearGradient id="paint0_linear_122_1240" x1="14" y1="0" x2="14" y2="27" gradientUnits="userSpaceOnUse">
                          <stop stop-color="#9100FF" />
                          <stop offset="1" stop-color="#C301AF" />
                        </linearGradient>
                        <clipPath id="clip0_122_1240">
                          <rect width="20" height="21" fill="white" transform="translate(5 4)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <input type="text" className={style.inputControl} placeholder="Search"
                    value={searchText}
                    onChange={e => handleChange(e.target.value)} />
                </div>
              </div>
            </div>
            <hr className={style.hr} />
            <div className="row">
              <div className="col-md-8">
                <h1 className={style.allItems}>
                  <b>4,221</b> &nbsp; items
                </h1>
              </div>
              <div className="col-md-8">
                <h1 className={style.blockTitle}>Collections</h1>
              </div>
              <div className="col-md-8">
                <div className="text-end">
                  <button className={style.filterBtn}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#C301AF" stroke="#C301AF" />
                      <path d="M11.4262 13.8241C11.7587 13.8241 12.0283 14.0937 12.0283 14.4262C12.0283 14.7587 11.7587 15.0283 11.4262 15.0283H7.81345C7.48091 15.0283 7.21133 14.7587 7.21133 14.4262C7.21133 14.0937 7.48091 13.8241 7.81345 13.8241H11.4262ZM13.0319 10.412C13.3644 10.412 13.634 10.6816 13.634 11.0142C13.634 11.3467 13.3644 11.6163 13.0319 11.6163H6.20779C5.87524 11.6163 5.60566 11.3467 5.60566 11.0142C5.60566 10.6816 5.87524 10.412 6.20779 10.412H13.0319ZM14.6375 7C14.9701 7 15.2396 7.26958 15.2396 7.60212C15.2396 7.93467 14.9701 8.20425 14.6375 8.20425H4.60212C4.26958 8.20425 4 7.93467 4 7.60212C4 7.26958 4.26958 7 4.60212 7H14.6375Z" fill="white" />
                    </svg> &nbsp; &nbsp;
                    Filter Item</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-24">
                <ul class={"d-flex justify-content-center mt-3 mt-md-0 " + style.nav}>
                  <li className={style.navItem}>
                    <button className={style.navLink + " " + style.navLinkActive}>All Items</button>
                  </li>
                  <li className={style.navItem}>
                    <button className={style.navLink}>Land</button>
                  </li>
                  <li className={style.navItem}>
                    <button className={style.navLink}>Houses</button>
                  </li>
                  <li className={style.navItem}>
                    <button className={style.navLink}>Games</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container-fluid section-x-margins">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5 row-cols-xxl-5">

              {productListRender()}

            </div>
          </div>
        </section>
      </DefaultLayout>
    </>
  );
}

export default Marketplace;
