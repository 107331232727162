import { AnimationOnScroll } from "react-animation-on-scroll";
import { Button, Col, Row } from "react-bootstrap";

import boxes from "../../Assets/img/boxes.png";
import "./TextBlock.scss";

function TextImageBlock(props) {
  return (
    <section className="pt-lg pb-lg">
      <div className="container-fluid section-x-margins" id="">
        <div className="row align-items-center">
          <div className="col-md-12 order-2 order-md-1">
            <div className="">
              <img className="img-fluid" src={props.imgPath} alt="card-img" />
            </div>
          </div>
          <div className="col-md-12 order-1 order-md-2">
            <div className="mb-4 mb-md-0">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                animationOut="animate__fadeOut"
                animateOnce={true}
              >
                <h3 className="text-image__sub-heading">
                  {props.small_text}
                </h3>
                <h1 className="text-image__heading">{props.title}</h1>
                <p className="text-image__description mb-5">{props.description}</p>
                <Button className="btn btn-main" href={props.btnLink} target="_blank">
                  {props.btnText}
                  {/* {props.isBlog ? "Blog" : "GitHub"} */}
                  {/* {!props.isBlog && props.btnLink && ()} */}
                </Button>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

TextImageBlock.defaultProps = {
  imgPath: boxes,
  // isBlog={false}
  small_text: "CRYPTOCURRENCY",
  title: "About Our Cryptocurrency",
  description: "With a shared passion for luxury, technological innovation, and gamification, we created a friendly, interactive, and multi-functioning platform for customers and vendors to interact in a holistic metaverse experience.",
  btnText: "LAUNCH WALLET NOW",
  btnLink: "#"
};
export default TextImageBlock;
