import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import { Col, Row } from "react-bootstrap";

import "./Products.scss";
import ProductSlider1 from "./ProductSlider1";
import ProductSlider2 from "./ProductSlider2";

function Products() {

  return (
    <section className="products-section" id="products">
      <div className="container-fluid section-x-margins">
        <div className="pt-normal pb-normal">
          <Row className="justify-content-center">
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="">

              <ProductSlider1 />

            </Col>
          </Row>
        </div>
        <div className="pt-normal pb-normal">
          <Row className="justify-content-center">
            <Col xl={24} lg={24} md={24} sm={24} xs={24} className="">

              <ProductSlider2 />

            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default Products;

