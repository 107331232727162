import NavBar from "./../Components/Shared/NavBar/NavBar";

const DefaultLayout = ({ children }) => {
  return (
    <>
      <header>
        <NavBar />
      </header>
      <main>{children}</main>
    </>
  );
};

export default DefaultLayout;
