import { Suspense, useEffect } from "react";
import { Helmet } from 'react-helmet';
// import { useAuthentication } from "./Hooks/useAuthentication";

import ProtectedRoute from "./ProtectedRoute";
import HomePage from "./Pages/Home/HomePage";
import MapPage from "./Pages/Map/MapPage";
// const Marketplace = lazy(() => import('./Pages/Marketplace/Marketplace'));
import ConnectMetaMask from "./Pages/Auth/ConnectMetaMask";
import Dashboard from "./Pages/Auth/Dashboard";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import Marketplace from "./Pages/Marketplace/Marketplace";
import MarketplaceDetail from "./Pages/Marketplace/MarketplaceDetail";
import NftDetailPage from "./Pages/NFT/NftDetailPage";
import NotFoundPage from "./Pages/NotFoundPage/NotFoundPage";

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

function RouterPages() {
  // const [user, isAuthenticated, authLogin, authLogout] = useAuthentication();
  // const [load, upadateLoad] = useState(true);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   upadateLoad(false);
    // }, 1200);

    // return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Helmet>
        <title>Xnation Metaverse</title>
        <meta name="description" content="App Description" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>

      {/* <Preloader load={load} /> */}
      <>
        <Suspense fallback={<div className="app-loader">Loading...</div>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/nft/detail/:id" element={<NftDetailPage />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/marketplace2" element={
              <ProtectedRoute>
                <Marketplace />
              </ProtectedRoute>
            } />
            <Route path="/marketplace/detail/:id" element={<MarketplaceDetail />} />
            <Route path="/connect-metamask" element={<ConnectMetaMask />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="404" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </>
    </Router>
  );
}

export default RouterPages;
