import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useToaster } from "react-hot-toast/headless";
import toast, { Toaster } from "react-hot-toast";

// import { Cursor } from "custom-pointer-react";
import AnimatedCursor from "react-animated-cursor";

import RouterPages from "./RouterPages";
import mp3RightClick from "./Assets/Audio/switch-on.mp3";
import mp3Click from "./Assets/Audio/plunger-immediate.mp3";

import "./App.scss";
import "animate.css/animate.min.css";

function App() {
  useEffect(() => {}, []);

  document.addEventListener("click", (event) => {
    // event.preventDefault();
    const isButton = event.target.nodeName === "BUTTON";
    const isLink = event.target.nodeName === "A";
    if (!isButton && !isLink) {
      return;
    }
    // console.dir(event.target.id);
    var audio1 = new Audio(mp3Click);
    audio1.volume = 0.1;
    audio1.play();
  });

  return (
    <>
      <Helmet>
        <title>Xnation Metaverse</title>
        <meta name="description" content="App Description" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
      <Toaster
        // toast={t}
        style={{}} // Overwrite styles
        position="bottom-center" // Used to adapt the animation
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: "",
          duration: 4000,
          style: {
            background: "#363636",
            color: "#fff",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />

      {/* <Cursor
        showRing={true}
        color="#C301AF"
        ringSize={34}
        cursorSize={12}
        ringBorder={1}
      /> */}
      {/* <Cursor className="janu" isGelly={true} /> */}
      <AnimatedCursor
        innerSize={11}
        outerSize={32}
        color="195,1,175"
        outerAlpha={0.25}
        innerScale={0.9}
        outerScale={3}
        hasBlendMode={true}
        outerStyle={{
          mixBlendMode: "exclusion",
        }}
      />
      <RouterPages />
    </>
  );
}

export default App;
