import { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Navbar, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { genericHashLink } from "react-router-hash-link";
import useDarkMode from "use-dark-mode";

import "./NavBar.scss";
import { ReactComponent as Logo } from "./../NavBar/logo.svg";

const MyLink = genericHashLink(NavLink);

function AuthNavbar() {
  const navigate = useNavigate();
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const darkMode = useDarkMode(false);

  useEffect(() => {}, []);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="lg"
      className={navColour ? "sticky" : "navbar"}
    >
      <div className="container-fluid section-x-margins">
        <div className="navbar-toggler-wrapper">
          <MyLink className="d-flex btn-link navbar-brand" to="/">
            <Logo
              className="img-fluid logo"
              style={!darkMode.value ? { filter: "invert(1)" } : {}}
            />
          </MyLink>
          <div className="d-block d-md-none me-2 ms-auto">
            <Button
              onClick={() => darkMode.toggle()}
              className="btn btn-main dark-mode"
            >
              {darkMode.value ? "🌙" : "☀️"}
              {/* <span>🌒</span> */}
            </Button>
          </div>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item className="d-none d-md-block ms-3">
              <Button onClick={() => darkMode.toggle()} className="dark-mode">
                {darkMode.value ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      fill="white"
                      d="M19 9.199h-.98c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799H19c.552 0 1-.357 1-.799 0-.441-.449-.801-1-.801zM10 4.5A5.483 5.483 0 0 0 4.5 10c0 3.051 2.449 5.5 5.5 5.5 3.05 0 5.5-2.449 5.5-5.5S13.049 4.5 10 4.5zm0 9.5c-2.211 0-4-1.791-4-4 0-2.211 1.789-4 4-4a4 4 0 0 1 0 8zm-7-4c0-.441-.449-.801-1-.801H1c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799h1c.551 0 1-.358 1-.799zm7-7c.441 0 .799-.447.799-1V1c0-.553-.358-1-.799-1-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1zm0 14c-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1 .441 0 .799-.447.799-1v-1c0-.553-.358-1-.799-1zm7.365-13.234c.391-.391.454-.961.142-1.273s-.883-.248-1.272.143l-.7.699c-.391.391-.454.961-.142 1.273s.883.248 1.273-.143l.699-.699zM3.334 15.533l-.7.701c-.391.391-.454.959-.142 1.271s.883.25 1.272-.141l.7-.699c.391-.391.454-.961.142-1.274s-.883-.247-1.272.142zm.431-12.898c-.39-.391-.961-.455-1.273-.143s-.248.883.141 1.274l.7.699c.391.391.96.455 1.272.143s.249-.883-.141-1.273l-.699-.7zm11.769 14.031l.7.699c.391.391.96.453 1.272.143.312-.312.249-.883-.142-1.273l-.699-.699c-.391-.391-.961-.455-1.274-.143s-.248.882.143 1.273z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="21.5 16.4 57.82 68.2"
                  >
                    <path
                      fill="black"
                      d="M76.4 28.6c.9-.1 1.7-.8 1.9-1.7.3-.9 0-1.9-.8-2.5-6.1-5.1-13.9-8-21.9-8-18.8 0-34.1 15.3-34.1 34.1s15.3 34.1 34.1 34.1c8.4 0 16.6-3.1 22.9-8.9.7-.6 1-1.6.7-2.5-.3-.9-1.1-1.5-2-1.7C66.3 70.3 58.1 61 58.1 50c0-10.7 7.7-19.7 18.3-21.4zM53.3 50c0 11.6 7.5 21.6 18.1 25.1-4.7 3-10.1 4.6-15.7 4.6-16.1 0-29.3-13.1-29.3-29.3s13.1-29.3 29.3-29.3c5.2 0 10.3 1.4 14.8 4C60.3 29 53.3 38.7 53.3 50z"
                    ></path>
                  </svg>
                )}
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default AuthNavbar;
