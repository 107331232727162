import React, { useEffect, useState } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow, } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng, } from "use-places-autocomplete";
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption, ComboboxOptionText } from "@reach/combobox";
import { formatRelative } from "date-fns";
import { Link } from 'react-router-dom';
import "@reach/combobox/styles.css";
import useDarkMode from "use-dark-mode";
import Modal from 'react-bootstrap/Modal';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";

import NftCard from "./../NftCard/NftCard";
import NftInfo from "./../NftCard/NftInfo";
import mapStyles from "./mapStyles";
import mapStylesDark from "./mapStylesDark";
import markersData from "./markers";
import markerIcon from "./img/browser-safari.svg";
import nftIcon from "./img/nft.png";
import { ReactComponent as CompassIcon } from "./img/compass.svg";

import mapcss from "./MapSearch.module.scss";
import formCSS from "./Form.module.scss";

const libraries = ["places"];
const mapContainerStyle = {
  height: "92vh",
  width: "100%",
};

const center = {
  lat: 30.6715098,
  lng: 73.1110572,
};

const creaNFTSchema = yup.object().shape({
  nft_name: yup.string()
    .required("NFT Name is required.")
    .min(3, 'NFT Name is too short - should be 8 chars minimum.')
    .max(60, "NFT Name should be miximum 50 chars long."),
  nft_address: yup.string().required("Address is required."),
  nft_price: yup.number("NFT Price must be a `number.").required("NFT Price is required.").default("").positive("NFT Price must be a `number.").integer("AgNFT Pricee must be a `number."),
});

export default function MapsSearch() {
  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    // libraries,
    googleMapsApiKey: "AIzaSyB9N9EKfx_VARWfYPraovEvhOQq6HQA42k",
    libraries,
  });
  const darkMode = useDarkMode(false);
  const [markers, setMarkers] = useState(markersData);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [firstTimeMapLoad, setFirstTimeMapLoad] = useState(0);
  const options = {
    styles: darkMode.value ? mapStylesDark : mapStyles,
    // styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false
  };

  useEffect(() => {
    document.body.classList.add(`nav-has-bg`);
    // console.log(selected.hasOwnProperty("lat"));
  }, [filteredMarkers]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onMapClick = React.useCallback((e) => {
    setShow(true);
    setMarkers((current) => [
      ...current,
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapCenterChanged = React.useCallback((map) => {
    console.log('onMapCenterChanged!');
    // console.log("onMapCenterChanged 1 ");
    // console.log(mapRef.current.getBounds().toJSON()); 
    // console.log(mapRef.current.getZoom());
    // console.log("getBounds().getNorthEast().lat() " + mapRef.current.getBounds().getNorthEast().lat());

    if (markers) {
      const filteredMarkers = markers.filter(marker => {
        if (
          marker.lat < mapRef.current.getBounds().getNorthEast().lat()
          && marker.lat > mapRef.current.getBounds().getSouthWest().lat()
          && marker.lng < mapRef.current.getBounds().getNorthEast().lng()
          && marker.lng > mapRef.current.getBounds().getSouthWest().lng()
        ) {
          return marker;
        }
      });
      setFilteredMarkers(filteredMarkers);
      // console.log(filteredMarkers);
    }
  }, []);

  const onMapZoomChange = React.useCallback((map) => {
    if (isMapLoaded) {
      if (markers) {
        const filteredMarkers = markers.filter(marker => {
          if (
            marker.lat < mapRef.current.getBounds().getNorthEast().lat()
            && marker.lat > mapRef.current.getBounds().getSouthWest().lat()
            && marker.lng < mapRef.current.getBounds().getNorthEast().lng()
            && marker.lng > mapRef.current.getBounds().getSouthWest().lng()
          ) {
            return marker;
          }
        });
        setFilteredMarkers(filteredMarkers);
      }
    } else {
      // console.log("onMapZoomChange 3 ");
    }
  }, []);
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    setIsMapLoaded(true);
    setFirstTimeMapLoad(1);
    // console.log("onMapLoad 1 ");
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(creaNFTSchema)
  });
  const onSubmit = (data) => {
    alert(JSON.stringify(data));
    var newNFT = {
      "id": 12345,
      "lat": 30.6715098,
      "lng": 73.1110572,
      "name": "NFT",
      "map_level": "Floor",
      "address": "Pakistan",
      "price": "$",
      "owned_by": "",
      "description": "",
      "thumbnails": ["1"]
    }
  };

  return (
    <section>
      <div>
        <div className="row g-0 align-items-stretch">
          <div className="col-md-11 h-100vh order-2 order-md-1" style={{ background: '#42245B' }}>
            <br /><br /><br /><br />
            <div className="ps-3 pe-2">
              <p className={mapcss["search-text"] + " text-light mt-2"}>
                {/* <button variant="primary" onClick={handleShow}>
                  Launch static backdrop modal
                </button> */}
                <b className={mapcss["search-text__total"]}> {filteredMarkers.length} </b> &nbsp;
                <span className={mapcss["search-text__text1"]}> NFT Results in your area :</span>  &nbsp;
                {/* <b className="search-text__text2">St13, H 10 new orleans newyork, USA</b> */}
              </p>
              <div className={mapcss["nfts-list"]} style={{ overflow: "scroll" }}>
                <div className="row g-3 align-items-stretch">
                  {(() => {
                    if (filteredMarkers.length >= 1) {
                      return (
                        <>
                          {filteredMarkers.map((marker, index) => (
                            <div key={index} className="col-md-12 h-100">
                              <div
                              // onClick={() => {
                              //   setSelected(marker);
                              // }}
                              >
                                <NftCard item={marker} />
                              </div>
                            </div>
                          ))}
                        </>
                      );
                    } else {
                      return (
                        <></>
                      );
                    }
                  })()}

                  <br /><br /><br />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-13 h-100vh order-1 order-md-2">
            <div className="position-relative">
              <br /><br /><br />
              <div className={mapcss["option-wrapper"]}>
                <Search className="form-control" panTo={panTo} />
                <Locate panTo={panTo} />
              </div>

              {(() => {
                if (loadError) {
                  return (
                    <>
                      <br /><br /><br /><br />
                      <p>Error loading map.</p>
                    </>
                  );
                }

                if (!isLoaded) return (
                  <>
                    <br /><br /><br /><br />
                    <p>Loading...</p>
                  </>
                );
              })()}

              {isLoaded && !loadError ? (
                <GoogleMap
                  id="map"
                  mapContainerStyle={mapContainerStyle}
                  zoom={9}
                  center={center}
                  options={options}
                  // onRightClick={}
                  onZoomChanged={onMapZoomChange}
                  onDragEnd={onMapCenterChanged}
                  onBoundsChanged={onMapCenterChanged}
                  // onDblClick={}
                  // onCenterChanged={onMapCenterChanged}
                  onClick={onMapClick}
                  onLoad={onMapLoad}
                >
                  {markers.map((marker) => (
                    <Marker
                      key={`${marker.lat}-${marker.lng}`}
                      // label={marker.name}
                      title={marker.name}
                      position={{ lat: marker.lat, lng: marker.lng }}
                      onClick={() => {
                        setSelected(marker);
                        // setSelectedElement(element);
                        // console.log(marker); 
                        console.log(selected.hasOwnProperty("lat"));
                      }}
                      icon={nftIcon}
                    // icon={{
                    //   url: { nftIcon },
                    //   // url: "./nft-1.png",
                    //   origin: new window.google.maps.Point(0, 0),
                    //   anchor: new window.google.maps.Point(15, 15),
                    //   scaledSize: new window.google.maps.Size(30, 30),
                    // }}
                    >
                      {/* <InfoWindow>
                        <div>
                          Location of blue Marker
                        </div>
                      </InfoWindow> */}
                    </Marker>
                  ))}

                  {/* {selected.hasOwnProperty("lat") ? ( */}
                  {selected ? (
                    <InfoWindow className='p-0' style={{ padding: "0px", background: "transparent" }}
                      position={{ lat: selected.lat, lng: selected.lng }}
                      onCloseClick={() => {
                        setSelected(null);
                      }}
                    >

                      <NftInfo item={selected} />

                    </InfoWindow>
                  ) : null}
                </GoogleMap>
              ) : (
                <>
                  {/* <h3>Something went wrong! </h3> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> ➕ Create NFT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={formCSS.control}>
              <label className={formCSS.controlLabel} htmlFor="nft_name">NFT Name*</label>
              <input className={formCSS.controlInput} {...register("nft_name")} placeholder="Enter nft name" id="nft_name" />
              {errors.nft_name && <p className={formCSS.controlMsg}>{errors.nft_name.message}</p>}
            </div>
            <div className={mapcss.control}>
              <label className={formCSS.controlLabel} htmlFor="nft_address">Address*</label>
              <input className={formCSS.controlInput} {...register("nft_address")} type="email" placeholder="Enter address" id="nft_address" />
              {errors.nft_address && <p className={formCSS.controlMsg}>{errors.nft_address.message}</p>}
            </div>
            <div className={formCSS.control}>
              <label className={formCSS.controlLabel} htmlFor="nft_price">Price*</label>
              <input className={formCSS.controlInput} type="number" {...register("nft_price")} placeholder="Add NFT Price" id="nft_price" />
              {errors.nft_price && <p className={formCSS.controlMsg}>{errors.nft_price.message}</p>}
            </div>
            <button type="submit" className="btn btn-gradient w-100 my-4">SUBMIT</button>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-gradient" onClick={handleClose}>
            Close
          </button>
          <button className="btn btn-main">SUBMIT</button>
        </Modal.Footer>
      </Modal>
    </section >
  );
}

function Locate({ panTo }) {
  return (
    <button
      className={mapcss["locate"]}
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <CompassIcon className={mapcss["locate__icon"]} />
    </button>
  );
}

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
    },
  });

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <>
      <Combobox className={mapcss["search"] + " me-2"} onSelect={handleSelect}>
        <svg className={mapcss["search__icon"]} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.0002 13.0001C13.596 13.0001 14.1062 12.7877 14.5309 12.3631C14.9549 11.9391 15.1668 11.4292 15.1668 10.8334C15.1668 10.2376 14.9549 9.72733 14.5309 9.30266C14.1062 8.87872 13.596 8.66675 13.0002 8.66675C12.4043 8.66675 11.8944 8.87872 11.4705 9.30266C11.0458 9.72733 10.8335 10.2376 10.8335 10.8334C10.8335 11.4292 11.0458 11.9391 11.4705 12.3631C11.8944 12.7877 12.4043 13.0001 13.0002 13.0001ZM13.0002 23.8334C10.0932 21.3598 7.92222 19.0621 6.48716 16.9402C5.05138 14.819 4.3335 12.8556 4.3335 11.0501C4.3335 8.34175 5.20486 6.18411 6.94758 4.57716C8.68958 2.97022 10.7071 2.16675 13.0002 2.16675C15.2932 2.16675 17.3107 2.97022 19.0527 4.57716C20.7955 6.18411 21.6668 8.34175 21.6668 11.0501C21.6668 12.8556 20.9493 14.819 19.5142 16.9402C18.0785 19.0621 15.9071 21.3598 13.0002 23.8334Z" fill="url(#paint0_linear_667_3105)" />
          <defs>
            <linearGradient id="paint0_linear_667_3105" x1="13.0002" y1="2.16675" x2="13.0002" y2="23.8334" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FE01E4" />
              <stop offset="1" stop-color="#A733FF" />
            </linearGradient>
          </defs>
        </svg>

        <ComboboxInput
          className={mapcss["search__input"]}
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Search Location"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption className="text-dark" key={id} value={description}>
                  {/* 🍊 */}
                  <ComboboxOptionText className="text-dark" />
                </ComboboxOption>
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </>
  );
}

function GoogleMapArea({ panTo }) {
  return (
    <>
    </>
  );
}
