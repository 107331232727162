import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import { Link } from 'react-router-dom';
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";

import nftCss from "./NftCard.module.scss";

function NftCard({ item }) {

  return (
    <div className={nftCss['nft']}>
      {(() => {
        if (item.thumbnails.length <= 1) {
          return (
            <>
              {item.thumbnails.map((thumb, index) => {
                return (
                  <img className={nftCss["nft__media"] + " img-fluid w-100"} src={require(`./img/nft-thumb-${thumb}.jpg`)} alt={item.name} />
                );
              })}
            </>
          );
        } else {
          return (
            <Splide hasTrack={false} aria-label="..." className="nft-splide p-0"
              aria-label=""
              options={{
                rewind: false,
                type: "fade",
                slideIndex: 1,
                isNavigation: true,
                pagination: false,
                arrows: true,
                gap: "0rem",
                padding: "0rem",
                perMove: 1,
                perPage: 1,
                autoplay: false,
                resetProgress: false,
              }}>
              <div className="nft-wrapper">
                <SplideTrack>
                  {item.thumbnails.map((thumb, index) => {
                    return (
                      <SplideSlide key={index.toString()}>
                        <img className={nftCss["nft__media"] + " img-fluid w-100"} src={require(`./img/nft-thumb-${thumb}.jpg`)} alt={item.name} />
                      </SplideSlide>
                    );
                  })}
                </SplideTrack>

                <div className={nftCss["arrows"] + " splide__arrows"}>
                  <button className={nftCss["arrow"] + " splide__arrow splide__arrow--prev"}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.28623 2.05413L12.2539 8.00413C12.3248 8.07497 12.3748 8.1517 12.4041 8.23434C12.4339 8.31698 12.4487 8.40552 12.4487 8.49997C12.4487 8.59441 12.4339 8.68295 12.4041 8.76559C12.3748 8.84823 12.3248 8.92497 12.2539 8.9958L6.28623 14.9635C6.12095 15.1288 5.91436 15.2114 5.66644 15.2114C5.41852 15.2114 5.20602 15.1229 5.02894 14.9458C4.85186 14.7687 4.76331 14.5621 4.76331 14.326C4.76331 14.0899 4.85186 13.8833 5.02894 13.7062L10.2352 8.49997L5.02894 3.29372C4.86366 3.12844 4.78102 2.92491 4.78102 2.68313C4.78102 2.44088 4.86956 2.23122 5.04665 2.05413C5.22373 1.87705 5.43033 1.78851 5.66644 1.78851C5.90255 1.78851 6.10915 1.87705 6.28623 2.05413Z" fill="black" />
                    </svg>
                  </button>
                  <button className={nftCss["arrow"] + " splide__arrow splide__arrow--next"}>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.28623 2.05413L12.2539 8.00413C12.3248 8.07497 12.3748 8.1517 12.4041 8.23434C12.4339 8.31698 12.4487 8.40552 12.4487 8.49997C12.4487 8.59441 12.4339 8.68295 12.4041 8.76559C12.3748 8.84823 12.3248 8.92497 12.2539 8.9958L6.28623 14.9635C6.12095 15.1288 5.91436 15.2114 5.66644 15.2114C5.41852 15.2114 5.20602 15.1229 5.02894 14.9458C4.85186 14.7687 4.76331 14.5621 4.76331 14.326C4.76331 14.0899 4.85186 13.8833 5.02894 13.7062L10.2352 8.49997L5.02894 3.29372C4.86366 3.12844 4.78102 2.92491 4.78102 2.68313C4.78102 2.44088 4.86956 2.23122 5.04665 2.05413C5.22373 1.87705 5.43033 1.78851 5.66644 1.78851C5.90255 1.78851 6.10915 1.87705 6.28623 2.05413Z" fill="black" />
                    </svg>
                  </button>
                </div>

              </div>
            </Splide>
          );
        }
      })()}

      <Link to="/nft/detail/123" className={nftCss[`nft__body`]}>
        <p className={nftCss["nft__name"] + " text-truncate"}>{item.name}</p>
        <address className={nftCss["nft__address"] + " text-truncate"}>
          <svg className={nftCss["nft__icon"]} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.0425 7.39375C17.1237 3.35125 13.5975 1.53125 10.5 1.53125C10.5 1.53125 10.5 1.53125 10.4912 1.53125C7.40246 1.53125 3.86746 3.3425 2.94871 7.385C1.92496 11.9 4.68996 15.7237 7.19246 18.13C8.11996 19.0225 9.30996 19.4688 10.5 19.4688C11.69 19.4688 12.88 19.0225 13.7987 18.13C16.3012 15.7237 19.0662 11.9087 18.0425 7.39375ZM10.5 11.7775C8.97746 11.7775 7.74371 10.5438 7.74371 9.02125C7.74371 7.49875 8.97746 6.265 10.5 6.265C12.0225 6.265 13.2562 7.49875 13.2562 9.02125C13.2562 10.5438 12.0225 11.7775 10.5 11.7775Z" fill="#FE1BE6" />
          </svg>
          {item.address}
        </address>
        <div className="row">
          <p className={nftCss["nft__map_level"] + " text-truncate col-md-12 col-sm-14 col-14"}>
            <svg className={nftCss["nft__icon"]} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.875 14.875H11.375V0.25H15.875V14.875ZM12.5 13.75H14.75V1.375H12.5V13.75ZM10.25 14.875H5.75V4.75H10.25V14.875ZM4.625 14.875H0.125V8.125H4.625V14.875Z" fill="#FE1BE6" />
            </svg>
            {item.map_level}
          </p>
          <p className={nftCss["nft__price"] + " text-truncate col-md-12 col-sm-14 col-14"}>
            <svg className={nftCss["nft__icon"]} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 3.5H7C4 3.5 2 5 2 8.5V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V8.5C22 5 20 3.5 17 3.5ZM6.25 14.5C6.25 14.91 5.91 15.25 5.5 15.25C5.09 15.25 4.75 14.91 4.75 14.5V9.5C4.75 9.09 5.09 8.75 5.5 8.75C5.91 8.75 6.25 9.09 6.25 9.5V14.5ZM12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15ZM19.25 14.5C19.25 14.91 18.91 15.25 18.5 15.25C18.09 15.25 17.75 14.91 17.75 14.5V9.5C17.75 9.09 18.09 8.75 18.5 8.75C18.91 8.75 19.25 9.09 19.25 9.5V14.5Z" fill="#FE1BE6" />
            </svg>
            {item.price}
          </p>
        </div>
        <p className={nftCss["nft__owned-by"] + " text-truncate"}> Owned by: <b>{item.owned_by}</b></p>
      </Link>
    </div>
  );
}

export default NftCard;
