import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import Tilt from "react-parallax-tilt";
import { Link } from 'react-router-dom';

import classes from "./Product.module.scss";

function Product({ item }) {

  return (
    <div className={"text-center " + classes.product}>
      <div className={classes.product__inner}>
        <Tilt
          className="h-100"
          perspective={800}
          tiltMaxAngleY="5"
          tiltMaxAngleX="8"
          scale={1.0}
          tiltReverse={true}
          trackOnWindow={false}
          transitionSpeed={900}
          reset={true}
        >
        </Tilt>
        <div className={classes.product__body}>
          <img
            src={item.avatar}
            className={"rounded mx-auto " + classes.product__avatar}
            alt="..."
          />
          <Link to={`/marketplace/detail/${item.product_no}`} className={classes.product__link}>
            Go to Market
          </Link>
        </div>
      </div>
      <div className={classes.product__footer}>
        <div className="d-flex justify-content-between">
          <h3 className={classes.product__name}>{item.name}</h3>
          <p className={classes.product__stock}>
            {item.in_stock} in stock
          </p>
        </div>
        <hr class={classes.product__border} />
        <div className="d-flex justify-content-between">
          <h3 className={classes.product__no}> #{item.product_no}</h3>
          <p className={classes.product__price}>
            {item.price} {item.currency}
          </p>
        </div>
      </div>
    </div >
  );
}

export default Product;
