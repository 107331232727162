import { useEffect, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { genericHashLink, HashLink } from "react-router-hash-link";
import { NavHashLink } from "react-router-hash-link";
import useDarkMode from "use-dark-mode";

import { useAuthentication } from "./../../../Hooks/useAuthentication";
// import { useDarkMode } from "./../../../Hooks/useDarkMode";
import { ReactComponent as Logo } from "./../NavBar/logo.svg";

import "./NavBar.scss";

// const NavHashLink = genericHashLink(NavLink);

function NavBar() {
  const navigate = useNavigate();
  const [user, isAuthenticated, authLogin, authLogout] = useAuthentication();
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  // const [theme, darkMode.toggle, setMode] = useDarkMode();
  const darkMode = useDarkMode(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  useEffect(() => {
    // setMode(window.localStorage.getItem("theme"));
  }, []);

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="lg"
      className={navColour ? "sticky" : "navbar"}
    >
      <div className="container-fluid section-x-margins">
        <div className="navbar-toggler-wrapper">
          {/* <Navbar.Brand to="/" className="d-flex"></Navbar.Brand> */}
          <NavHashLink className="d-flex btn-link navbar-brand" to="/">
            <Logo
              className="img-fluid logo"
              style={!darkMode.value ? { filter: "invert(1)" } : {}}
            />
          </NavHashLink>
          <div className="d-block d-md-none me-2 ms-auto">
            <Button
              onClick={() => darkMode.toggle()}
              className="btn btn-main px-3 dark-mode"
            >
              {darkMode.value ? "🌙" : "☀️"}
              {/* <span>🌒</span> */}
            </Button>
          </div>
          <Navbar.Toggle
            className="navbar-toggler"
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              updateExpanded(expand ? false : "expanded");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-menu-button-wide-fill"
              viewBox="0 0 16 16"
            >
              <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v2A1.5 1.5 0 0 0 1.5 5h13A1.5 1.5 0 0 0 16 3.5v-2A1.5 1.5 0 0 0 14.5 0h-13zm1 2h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1zm9.927.427A.25.25 0 0 1 12.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0l-.396-.396zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
            </svg>
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/"
                onClick={() => updateExpanded(false)}
                activeClassName="active"
              >
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <NavHashLink
                className={({ isActive4 }) =>
                  isActive4 ? "nav-link active" : "nav-link"
                }
                activeClassName="active2"
                to="/#about_block"
                onClick={() => updateExpanded(false)}
              >
                About
              </NavHashLink>
            </Nav.Item>
            <Nav.Item>
              <NavHashLink
                className={({ isActive6 }) =>
                  isActive6 ? "nav-link active" : "nav-link"
                }
                to="/#roadmap_block"
                onClick={() => updateExpanded(false)}
              >
                Road Map
              </NavHashLink>
            </Nav.Item>
            <Nav.Item>
              <NavHashLink
                className="nav-link"
                className={({ isActive7 }) =>
                  isActive7 ? "nav-link active" : "nav-link"
                }
                activeClassName="active"
                // activeStyle={{ color: "red" }}
                to="/#team_block"
                onClick={() => updateExpanded(false)}
              >
                Team
              </NavHashLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/marketplace"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Market Place
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/map"
                className={({ isActive }) =>
                  isActive ? "nav-link active" : "nav-link"
                }
              >
                Discover
              </NavLink>
            </Nav.Item>
          </Nav>

          <Nav className="ms-auto align-items-center" defaultActiveKey="#home">
            {(() => {
              if (isAuthenticated) {
                return (
                  <button
                    className="btn btn-main px-3"
                    onClick={() => authLogout()}
                  >
                    Logout
                  </button>
                );
              } else {
                return (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        as={Link}
                        to="/register"
                        onClick={() => updateExpanded(false)}
                      >
                        Register Now
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="ms-3">
                      <Button
                        onClick={() => {
                          navigate(`/login`);
                        }}
                        className="btn btn-sm btn-main"
                      >
                        Log in
                      </Button>
                    </Nav.Item>
                  </>
                );
              }
            })()}

            <Nav.Item className="d-none d-md-block ms-3">
              <Button
                onClick={() => darkMode.toggle()}
                className="btn btn-main px-3 dark-mode"
              >
                {darkMode.value ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      fill="white"
                      d="M19 9.199h-.98c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799H19c.552 0 1-.357 1-.799 0-.441-.449-.801-1-.801zM10 4.5A5.483 5.483 0 0 0 4.5 10c0 3.051 2.449 5.5 5.5 5.5 3.05 0 5.5-2.449 5.5-5.5S13.049 4.5 10 4.5zm0 9.5c-2.211 0-4-1.791-4-4 0-2.211 1.789-4 4-4a4 4 0 0 1 0 8zm-7-4c0-.441-.449-.801-1-.801H1c-.553 0-1 .359-1 .801 0 .441.447.799 1 .799h1c.551 0 1-.358 1-.799zm7-7c.441 0 .799-.447.799-1V1c0-.553-.358-1-.799-1-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1zm0 14c-.442 0-.801.447-.801 1v1c0 .553.359 1 .801 1 .441 0 .799-.447.799-1v-1c0-.553-.358-1-.799-1zm7.365-13.234c.391-.391.454-.961.142-1.273s-.883-.248-1.272.143l-.7.699c-.391.391-.454.961-.142 1.273s.883.248 1.273-.143l.699-.699zM3.334 15.533l-.7.701c-.391.391-.454.959-.142 1.271s.883.25 1.272-.141l.7-.699c.391-.391.454-.961.142-1.274s-.883-.247-1.272.142zm.431-12.898c-.39-.391-.961-.455-1.273-.143s-.248.883.141 1.274l.7.699c.391.391.96.455 1.272.143s.249-.883-.141-1.273l-.699-.7zm11.769 14.031l.7.699c.391.391.96.453 1.272.143.312-.312.249-.883-.142-1.273l-.699-.699c-.391-.391-.961-.455-1.274-.143s-.248.882.143 1.273z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="21.5 16.4 57.82 68.2"
                  >
                    <path
                      fill="black"
                      d="M76.4 28.6c.9-.1 1.7-.8 1.9-1.7.3-.9 0-1.9-.8-2.5-6.1-5.1-13.9-8-21.9-8-18.8 0-34.1 15.3-34.1 34.1s15.3 34.1 34.1 34.1c8.4 0 16.6-3.1 22.9-8.9.7-.6 1-1.6.7-2.5-.3-.9-1.1-1.5-2-1.7C66.3 70.3 58.1 61 58.1 50c0-10.7 7.7-19.7 18.3-21.4zM53.3 50c0 11.6 7.5 21.6 18.1 25.1-4.7 3-10.1 4.6-15.7 4.6-16.1 0-29.3-13.1-29.3-29.3s13.1-29.3 29.3-29.3c5.2 0 10.3 1.4 14.8 4C60.3 29 53.3 38.7 53.3 50z"
                    ></path>
                  </svg>
                )}
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default NavBar;
