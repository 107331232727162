import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import useDarkMode from "use-dark-mode";

import Globe from "./../Globe/Globe";
// import Globe2 from "../Globe/Globe2";
import "./HeroHome.scss";

function HeroHome() {
  const darkMode = useDarkMode(false);

  useEffect(() => {
    // alert(theme);
  }, [darkMode, darkMode.value]);

  return (
    <section className="hero-section position-relative" id="home">
      <Globe isDarkMode={darkMode.value} />
      {/* <Globe2 /> */}
      <div className="bg-inner-globe">
        <div className="container-fluid section-x-margins">
          <Row className="justify-content-center">
            <Col xl={20} lg={20} md={22} sm={24} xs={24} className="">
              <br /> <br />
              <br /> <br />
              <br /> <br />
              <Tilt
                perspective={800}
                glareEnable={true}
                glareMaxOpacity={0.8}
                glareColor="#9100ff45"
                glarePosition="bottom"
                glareBorderRadius="25px"
                tiltMaxAngleY="5"
                tiltMaxAngleX="8"
                scale={1.0}
                trackOnWindow={false}
                transitionSpeed={700}
                reset={true}
              >
                <br /> <br />
                <div className="hero-home text-center animate__animated animate__zoomIn">
                  <h1 className="hero-home__sub-heading">
                    A NEW UNINVERSE IS BORN
                  </h1>
                  <h1 className="hero-home__heading">Xnation Metaverse</h1>
                  <p className="hero-home__description mb-5">
                    We believe every person, brand, and idea deserves seamless
                    entry ino the metaverse and easy access to the purchasing
                    power of crypocurrency.
                  </p>
                  <button className="btn btn-main">LAUNCH THE APP</button>
                  {/* <button className="btn btn-main mt-3">LAUNCH THE APP</button> */}
                </div>
                <br /> <br />
              </Tilt>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
