import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import "@splidejs/react-splide/css/sea-green";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Tilt from "react-parallax-tilt";
import { Link } from 'react-router-dom';

import product_1 from "./img/product_1.png";
import product_2 from "./img/product_2.png";
import product_3 from "./img/product_3.png";
import "./ProductSlider2.scss";

function ProductSlider2() {
  const productList = [
    {
      avatar: product_1,
      name: "the Modern Mona",
      price: "1.23",
      currency: "ETH"
    },
    {
      avatar: product_2,
      name: "Fluffy One",
      price: "2.22",
      currency: "ETH"
    },
    {
      avatar: product_3,
      name: "Stylo Negro",
      price: "1.00",
      currency: "ETH"
    },
    {
      avatar: product_3,
      name: "Wrath of titans",
      price: "1.43",
      currency: "ETH"
    },
  ];

  return (
    <Splide hasTrack={false} aria-label="..." className="products-splide px-0 px-md-0"
      aria-label=""
      // onArrowsMounted={(splide, prev, next) => { console.log(prev, next) }}
      options={{
        rewind: true,
        type: "loop",
        slideIndex: 2,
        isNavigation: true,
        pagination: false,
        paginationDirection: "ltr",
        arrows: true,
        autoHeight: true,
        padding: { left: "0rem", right: "0rem" },
        perMove: 1,
        perPage: 4,
        gap: "2rem",
        breakpoints: {
          340: {
            perPage: 1,
            gap: "0rem",
          },
          440: {
            perPage: 1,
            gap: "0rem",
          },
          640: {
            perPage: 2,
            gap: "1rem",
          },
          740: {
            perPage: 3,
            gap: "2rem",
          },
          1140: {
            perPage: 4,
            gap: "2rem",
          },
        },
        autoplay: false,
        pauseOnHover: false,
        resetProgress: false,
      }}>
      <div className="custom-wrapper">
        <div className="row align-items-start">
          <div className="col-md-18">
            <AnimationOnScroll
              animateIn="animate__bounce"
              animationOut="animate__fadeOut"
              // animateInDuration={800}
              animationOutDuration={800}
              delay={800}
              initiallyVisible={true}
              animateOnce={true}
            >
              <h1 className="products__heading">Top Buying</h1>
              <p className="products__description mb-5">
                Inspired by this vision of the future, our team of architects, 3D designers, marketers, and entrepreneurs created a powerful framework where every person, business
              </p>
            </AnimationOnScroll>
          </div>
          <div className="col-md-6">
            <div className="buying-slider-arrows">
              <div className="splide__arrows">
                <button className="splide__arrow splide__arrow--prev">
                  <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.7656 5.25L0 5.25L0 8.75L18.7656 8.75V14L25 7L18.7656 0V5.25Z" fill="white" />
                  </svg>
                </button>
                <button className="splide__arrow splide__arrow--next">
                  <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.7656 5.25L0 5.25L0 8.75L18.7656 8.75V14L25 7L18.7656 0V5.25Z" fill="white" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <SplideTrack>
          {productList.map((item, index) => {
            return (
              <SplideSlide key={index.toString()}>
                <div className="product text-center">
                  <div className="product__inner-wrapper">
                    <Tilt
                      className="h-100"
                      perspective={800}
                      tiltMaxAngleY="5"
                      tiltMaxAngleX="8"
                      scale={1.0}
                      tiltReverse={true}
                      trackOnWindow={false}
                      transitionSpeed={900}
                      reset={true}
                    >
                      <div className="product__body">
                        <img
                          src={item.avatar}
                          className="product__avatar rounded mx-auto"
                          alt="..."
                        />
                        <Link to="/marketplace" className="product__link" style={{ color: '#FFF' }}>
                          Go to Market
                        </Link>
                      </div>
                    </Tilt>
                  </div>
                  <div className="product__footer">
                    <h3 className="product__name">{item.name}</h3>
                    <p className="product__price">
                      {item.price} {item.currency}
                    </p>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
        </SplideTrack>
      </div>
    </Splide>
  );
}

export default ProductSlider2;
