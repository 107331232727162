import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css/sea-green";
import "@splidejs/react-splide/css/core";

import "./TeamsBlock.scss";
import avatar1 from "./img/avatar-1.png";
import avatar2 from "./img/avatar-2.png";
import avatar3 from "./img/avatar-3.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

function TeamsBlock() {
  const teamMembers = [
    {
      avatar: avatar1,
      name: "Ben Kendrick",
      designation: "CEO, Xnation",
    },
    {
      avatar: avatar2,
      name: "Nina Doubrev",
      designation: "CTO, Xnation",
    },
    {
      avatar: avatar3,
      name: "Christpoher Henry",
      designation: "Marketing Manager, Xnation",
    },
  ];

  return (
    <section className="hero-section pt-lg pb-lg" id="team_block">
      <div className="container-fluid section-x-margins">
        <div className="row justify-content-center">
          <Col xl={20} lg={20} md={22} sm={24} xs={24} className="">
            <div className="teams text-center">
              <AnimationOnScroll
                animateIn="animate__fadeIn"
                delay={200}
                animateOnce={true}
              >
                <h1 className="teams__sub-heading">HOW WE COMBINED</h1>
                <h1 className="teams__heading">Meet The Team</h1>
                <p className="teams__description mb-5">
                  Inspired by this vision of the future, our team of architects,
                  3D designers, marketers, and entrepreneurs created a powerful
                  framework where every person, business, or idea would have
                  seamless entry into the metaverse and easy access to the
                  purchasing power of Cryptocurrency.
                </p>
              </AnimationOnScroll>

              <Splide
                className="teams-splide"
                aria-label=""
                options={{
                  rewind: true,
                  type: "loop",
                  slideIndex: 2,
                  isNavigation: true,
                  pagination: true,
                  paginationDirection: "ltr",
                  arrows: false,
                  autoHeight: true,
                  gap: "3rem",
                  padding: { left: "1rem", right: "1rem" },
                  perMove: 1,
                  perPage: 3,
                  breakpoints: {
                    340: {
                      perPage: 1,
                      gap: "1rem",
                    },
                    440: {
                      perPage: 1,
                      gap: "1rem",
                    },
                    640: {
                      perPage: 1,
                      gap: "1rem",
                    },
                    740: {
                      perPage: 2,
                      gap: "2rem",
                    },
                    1140: {
                      perPage: 2,
                      gap: "3rem",
                    },
                  },
                  autoplay: false,
                  pauseOnHover: false,
                  resetProgress: false,
                }}
              >
                {teamMembers.map((item, index) => {
                  return (
                    <SplideSlide key={index.toString()}>
                      <div className="team">
                        <Tilt
                          className="h-100"
                          perspective={800}
                          glareEnable={true}
                          glareMaxOpacity={0.25}
                          glareColor="#FE01E4"
                          glarePosition="bottom"
                          glareBorderRadius="25px"
                          tiltMaxAngleY="5"
                          tiltMaxAngleX="8"
                          scale={1.0}
                          tiltReverse={true}
                          trackOnWindow={false}
                          transitionSpeed={900}
                          reset={true}
                        >
                          <div className="team__inner-wrapper">
                            <img
                              src={item.avatar}
                              className="team__avatar rounded mx-auto"
                              alt="..."
                            />
                            <h3 className="team__name">{item.name}</h3>
                            <p className="team__designation">
                              {item.designation}
                            </p>
                          </div>
                        </Tilt>
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          </Col>
        </div>
      </div>
    </section>
  );
}

export default TeamsBlock;
