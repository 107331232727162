import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import FeatureCards from "./FeatureCards";
import "./About.scss";

function About() {
  return (
    <section className="about-section pt-lg pb-lg" id="about_block">
      <div className="container-fluid section-x-margins">
        <Row className="justify-content-center">
          <Col xl={20} lg={20} md={22} sm={24} xs={24} className="">
            <br /> <br />
            <br /> <br />
            <div className="hero-about">
              <div
                className="animate__animated animate__zoomInDown"
              >
                <h1 className="hero-about__sub-heading text-center">
                  GET TO KNOW ABOUT US
                </h1>
                <h1 className="hero-about__heading text-center">
                  " To deliver the most impactful and human-focused
                  e-commerce experiences for the next generation of
                  consumers to connect, play, earn, and grow. "
                </h1>
                <Row className="justify-content-center">
                  <Col xl={12} lg={12} md={12} sm={12} xs={24} className="">
                    <p className="hero-about__description mb-5">
                      Inspired by this vision of the future, our team of architects, 3D designers, marketers, and entrepreneurs created a powerful framework where every person, business, or idea would have seamless entry into the metaverse and easy access to the purchasing power of Cryptocurrency.
                    </p>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12} xs={24} className="">
                    <p className="hero-about__description mb-5">
                      With a shared passion for luxury, technological innovation, and gamification, we created a friendly, interactive, and multi-functioning platform for customers and vendors to interact in a holistic metaverse experience.
                    </p>
                  </Col>
                </Row>
              </div>

              <FeatureCards />

            </div>
            <br /> <br />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default About;
