import NavBar from "../Components/Shared/NavBar/NavBar";
import AuthNavbar from "../Components/Shared/NavBar/AuthNavbar";

const AuthLayout = ({ children }) => {
  return (
    <>
      <header>
        <AuthNavbar />
      </header>
      <main>{children}</main>
    </>
  );
};

export default AuthLayout;
