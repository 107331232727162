import React from 'react';
import styles from "./MyAlert.module.scss";

function MyAlert({ type, text }) {
  const stDanger = {
    backgroundColor: "#0E0019",
    border: "2px solid red",
  }
  const alertInfo = {
    backgroundColor: "#0E0019",
    border: "2px solid #9100FF",
  }

  return (
    <div className={styles.alert + " alert-" + type}
      type={type}
      style={type === 'danger' ? stDanger : alertInfo}
      role="alert">
      <div className="" dangerouslySetInnerHTML={{ __html: text }} />
    </div >
  );
}
export default MyAlert;
