export default [
  {
    "id": 1,
    "lat": 31.1336067,
    "lng": 73.49826048,
    "name": "NFT Name",
    "map_level": "2nd Floor",
    "address": "Ontario, Sumandri, Pakistan",
    "price": "$434",
    "owned_by": "$545",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["1", "2", "3"]
  },
  {
    "id": 2,
    "lat": 31.06634499,
    "lng": 73.44799806,
    "name": "BLeU Painted Hills BLeU Painted Hills",
    "map_level": "2nd Floor",
    "address": "Anchorage, Alaska, Pakistan",
    "price": "$434",
    "owned_by": "Pfizer",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["2"]
  },
  {
    "id": 3,
    "lat": 30.95005358,
    "lng": 73.33126833,
    "name": "Fall Reflection",
    "map_level": "1st Floor",
    "address": "Edmond, Oklahoma, Pakistan",
    "price": "$5435",
    "owned_by": "Johnson",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["1", "2", "3"]
  },
  {
    "id": 4,
    "lat": 30.8236263,
    "lng": 72.92381287,
    "name": "Kilianwala Explore Collector Club",
    "map_level": "4th Floor",
    "address": "Wellington, Florida, Pakistan",
    "price": "$5435",
    "owned_by": "Superhuman",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["3"]
  },
  {
    "id": 5,
    "lat": 30.84682645,
    "lng": 72.82394027,
    "name": "NFT name here 5",
    "map_level": 165,
    "address": "Dearborn, Michigan, Pakistan",
    "price": "$555",
    "owned_by": "Alphabet",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["2", "1", "3"]
  },
  {
    "id": 6,
    "lat": 30.73250183,
    "lng": 72.88669967,
    "name": "NFT Sahib K.",
    "map_level": "2nd Floor",
    "address": "St13, H 10 new orleans newyork, USA",
    "price": "$5435",
    "owned_by": "Christen Stewart",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["4"]
  },
  {
    "id": 7,
    "lat": 31.30108306,
    "lng": 73.415039,
    "name": "NFT name here 7",
    "map_level": "1st Floor",
    "address": "Olympia, Washington, Pakistan",
    "price": "$223",
    "owned_by": "Segment",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["3"]
  },
  {
    "id": 8,
    "lat": 29.99157503,
    "lng": 73.25820923,
    "name": "NFT Bahwalnagar",
    "map_level": "2nd Floor",
    "address": "Mamunkanjan, Sumandri, Pakistan",
    "price": "$5435",
    "owned_by": "Brex",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["1"]
  },
  {
    "id": 9,
    "lat": 30.33708733,
    "lng": 73.37960819,
    "name": "NFT PakPattan",
    "map_level": "2nd Floor",
    "address": "Grand Rapids, Michigan, Pakistan",
    "price": "$5435",
    "owned_by": "Costco Wholesale",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["2"]
  },
  {
    "id": 10,
    "lat": 30.29796645,
    "lng": 73.04644774,
    "name": "NFT Arifwala",
    "map_level": "2nd Floor",
    "address": "Bullhead City, Arizona, Pakistan",
    "price": "$5435",
    "owned_by": "Brex",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["3"]
  },
  {
    "id": 11,
    "lat": 30.42025614,
    "lng": 73.04589847,
    "name": "NFT Kamir",
    "map_level": "2nd Floor",
    "address": "Roy, Utah, Pakistan",
    "price": "$22",
    "owned_by": "AT&T",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["1"]
  },
  {
    "id": 12,
    "lat": 30.71303177,
    "lng": 72.65203858,
    "name": "NFT KAMALIA",
    "map_level": "2nd Floor",
    "address": "Kamalia, Pakistan",
    "price": "$77",
    "owned_by": "Nationwide",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["3", "2", "3"]
  },
  {
    "id": 13,
    "lat": 30.52675611,
    "lng": 72.6833496,
    "name": "NFT Chichawatni",
    "map_level": "2nd Floor",
    "address": "Hayward, Sumandri, Pakistan",
    "price": "$5435",
    "owned_by": "JP Morgan Chase",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["2"]
  },
  {
    "id": 14,
    "lat": 30.78526151,
    "lng": 73.44854733,
    "name": "NFT OKARA",
    "map_level": "1st Floor",
    "address": "Sparks, Nevada, Pakistan",
    "price": "$5435",
    "owned_by": "Best Buy",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["4"]
  },
  {
    "id": 15,
    "lat": 30.6715098,
    "lng": 73.1110572,
    "name": "Sahiwal NFT",
    "map_level": "2nd Floor",
    "address": "Sahiwal, Pakistan",
    "price": "$5435",
    "owned_by": "Ford Motor",
    "description": "Sahiwal ipsum dolor sit amet, Sahiwal adipiscing elit, sed do Sahiwal tempor incididunt ut labore et dolore magna aliqua.",
    "thumbnails": ["1"]
  }
]